import React from "react";
import tw from "twin.macro";

const StyledRatingIcon = tw.div``;

const FeedbackIcon = ({ theme, item, current }) => {
	// if (current && current.id === item.id) {
	// 	console.log(item.title, current.id, item.id);
	// }

	const iconName = `icon${item.title.split(" ").join("")}`;
	const hoverIconName = `icon${item.title.split(" ").join("")}Hover`;

	return (
		<StyledRatingIcon>
			{current && current.id === item.id ? (
				<img src={theme[hoverIconName]} alt={item.title} title={item.title} aria-hidden="true" />
			) : (
				<img src={theme[iconName]} alt={item.title} title={item.title} aria-hidden="true" />
			)}
		</StyledRatingIcon>
	);
};

export default FeedbackIcon;
